@import url('https://fonts.googleapis.com/css2?family=Poetsen+One&display=swap');

*{
    font-family: Arial, Helvetica, sans-serif;
}

*::selection{
    color: var(--primary);
    background-color: var(--secondary);
}

:root{
    --primary: #FF7F00;
    --secondary: #333333;
    color: #f5f5f5;
}

a{
    text-decoration: none !important;
}

p{
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
}

/* h1,h2,h3{
    font-family: ;
} */

*{
    font-family: 'Noto Sans Georgian', sans-serif;
}

.offcanvas-backdrop{
    z-index: 2;
}

.fs-9s{
    font-size: .9rem;
}

.fs-8s{
    font-size: .8rem;
}

.fs-7s{
    font-size: .7rem;
}

.fs-6s{
    font-size: .6rem;
}

.fs-5s{
    font-size: .5rem;
}

.fs-4s{
    font-size: .4rem;
}

.text-justify{
    text-align: justify;
}

.color-primary{
    color: var(--primary);
}

.color-secondary{
    color: var(--secondary);
}

.bgcolor-primary{
    background-color: var(--primary);
}

.bgcolor-secondary{
    background-color: var(--secondary);
}

.bordercolor-primary{
    border-color: var(--primary) !important;
}

.react-responsive-modal-modal{
    border-radius: 15px;
}

.react-responsive-modal-root{
    z-index: 1031;
}


.form-control:focus {
    border-color: var(--primary);
    outline: 0;
    box-shadow: none;
}

body::-webkit-scrollbar{
    width: 10px;
}

body::-webkit-scrollbar-thumb{
    border-radius: 25px;
    background-color: var(--primary);
    transition: 0.7s;
}

body::-webkit-scrollbar-track{
    background-color: #3333332f;
    border-radius: 10px;
}

.text-one-line{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.loading-page{
    width: 100vw;
    height: 100vh;
}

.product-carousel .react-multi-carousel-item{
    width: auto !important;
}

.product-speeddial .p-speeddial-list{
    padding-left: 0px !important;
    padding-right: .1rem !important;
}



/* Remove header on login page */
#navbar:has(a[href="/login"].active){
    display: none;
}








/* ############ LOGIN ###############  */
.login-bg{
    background-color: #ecdfcb;
}

.login{
    min-height: 100vh;
}

.login-img{
    max-height: 80vh;
    box-shadow: inset gray 0px 0px 60px -12px; 
}

.login-brand{
    max-width: 8rem;
}

.p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
    color: var(--primary);
}

.p-rating-item.p-focus{
    box-shadow: 0 0 0 0.2rem #ffcb97;
}

.react-international-phone-country-selector-button{
    border: none;
    padding: 0;
}

.react-international-phone-input-container .react-international-phone-input{
    border: none;
}








/* ################# HEADER ############### */
.header-bg{
    background: linear-gradient(to top, rgba(255, 255, 255, .3),  rgba(255, 255, 255, .8),  rgba(255, 255, 255, 1),  rgba(255, 255, 255, 1),  rgba(255, 255, 255, 1));
    backdrop-filter: blur(10px);
}

header .dropdown-item.active, header .dropdown-item:active{
    background-color: #e9ecef;
    color: var(--primary);
}








/* ################# BIZNESS CARDS ################# */
.large-bizness-card{
    background-color: transparent;
    transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1);
}

.large-bizness-card:hover{
    background-color: rgba(33, 37, 41, 0.15);
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.offer-card-description{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}













/* ################# ACCOUNT ################## */
#account ul li a:hover, #account ul li a.active{
    border-bottom: 1px solid var(--primary);
    color: var(--primary) !important;
}














/* ################# BIZNESS ################## */

.biz-hero-overlay{
    background: linear-gradient(to right, rgb(32, 33, 36) 0, rgba(0, 0, 0, 0) 56%), linear-gradient(to top, rgb(32, 33, 36) 0, rgba(0, 0, 0, 0) 56%);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
}